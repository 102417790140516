import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  // Link
} from "react-router-dom";
import AppContent from './components/AppContent';
import Header from './components/Header';
import { AppContainer } from './styles';
import useDataStore from './services/DataService';
import useWindowStore from './services/WindowService';

function App() {

  useEffect(()=>{
    useWindowStore.getState().init();
    useDataStore.getState().test();
  }, [])

  return (
    <Router>
      <AppContainer className="App">
        <Routes>
          <Route path="/:page" element={<><Header /><AppContent /></>}/>
          <Route path='/' element={<Navigate to='/projekte' />} />
        </Routes>
      </AppContainer>
    </Router>
  );
}

export default App;
