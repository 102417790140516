import React, { useRef, useState } from 'react';
import ReactSwipe from 'react-swipe';
import { Container, SliderContainer, SlideContainer, NextButton, PreviousButton, Pagination, PaginationDot, ArrowContainer, HUDcontainer, SliderImg } from './styles';
import ArrowImgSrc from './arrow.png';
import useWindowStore from '../../services/WindowService';

export default function Project({project}) {
  const [imageIndex, setImageIndex] = useState(0);

  const windowHeight = useWindowStore(state => state.height);

  const reactSwipeEl = useRef();
  const onSlideChange = () => {
    setImageIndex(reactSwipeEl.current.getPos());
  };

  const swipeOptions = useRef({
    transitionEnd: onSlideChange,
    continuous: true,
    speed: 800
  });
  const previousSlide = () => { reactSwipeEl.current.prev() };
  const nextSlide = () => { reactSwipeEl.current.next() };

  const onPaginationClick = index => {
    reactSwipeEl.current.slide(index, 500);
    setImageIndex(index);
  }

  return (
    <Container>
      <SliderContainer>
        <ReactSwipe swipeOptions={swipeOptions.current} ref={reactSwipeEl} key={project.id}>
          {project.images.map((img, index) =>
            <SlideContainer key={index} style={{height: `${(windowHeight - 220)}px`}}>
              <SliderImg style={{backgroundImage: `url(${img.image})`}} hasDescription={img.description !== ''}>
                <p>{img.description}</p>
              </SliderImg>
            </SlideContainer>
          )}
        </ReactSwipe>
      </SliderContainer>
      <ArrowContainer>
        <PreviousButton onClick={previousSlide}>
          <div className='arrow-container'> <img src={ArrowImgSrc} alt='previous arrow'/></div>
        </PreviousButton>
        <NextButton onClick={nextSlide}>
          <div className='arrow-container'> <img src={ArrowImgSrc} alt='next arrow'/></div>
        </NextButton>
      </ArrowContainer>
      <HUDcontainer>
        {/* <MobileBG /> */}
        {project.images.length > 1 && (
          <Pagination key={project.id+"Pagination"}>
            {project.images.map((img, index) => <PaginationDot key={index} active={index === imageIndex} onClick={() => {onPaginationClick(index)}} />)}
          </Pagination>
        )}
      </HUDcontainer>
    </Container>
  );
}