import styled from 'styled-components';
import { mediaMax, mediaMin } from '../../common/breakpoints';

export const Container = styled.div`
  height: calc(100vh - 160px);
`;

export const InnerContainer = styled.div`
  position: relative;
  height: 100%;
`;

export const Image = styled.img`
  position: absolute;
  right: 0;
  height: 100%;
  /* float: right;
  max-height: 550px; */
  ${mediaMax('small',`
    display: none;
  `)}
`;

export const ImageDiv = styled.div`
  float: left;
  width: 100%;
  padding-bottom: 70%;
  margin-top: 20px;
  background-position-y: center;
  background-size: cover;
  ${mediaMin('medium',`
    display: none;
  `)}
`;

export const ContactContainer = styled.div`
  float: left;
  font-size: 14px;
  font-weight: 300;
  margin-top: 10px;
  ${mediaMin('medium',`
    margin-top: 0;
    font-size: 18px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  `)}
`;
