import React from 'react';
import { Container, ContactContainer, Image, ImageDiv, InnerContainer } from './styles';
import useDataStore from '../../services/DataService';

export default function Contact() {

  const data = useDataStore(state => state.contact);

  if (!data) return <div />;

  return (
    <Container>
      <InnerContainer>
        <Image src={data.singleimage} alt='' />
        <ContactContainer dangerouslySetInnerHTML={{__html: data.richtext}} />
        <ImageDiv style={{backgroundImage: `url(${data.singleimage})`}} alt='' />
      </InnerContainer>
    </Container>
  );
}