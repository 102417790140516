import React from 'react';
import { Container, Logo, MenuContainer, BurgerMenu, MenuItem } from './styles';
import { Link, useParams } from 'react-router-dom';
import useWindowStore from '../../services/WindowService';
import useNavigationStore from '../../services/NavigationService';

export default function Header() {

  const { page } = useParams();
  const isMobile = useWindowStore(state => state.isMobile);

  return (
    <Container>
      <Logo><Link to="/projekte"><b>mapet</b> Architekten</Link></Logo>
      {isMobile ? 
        <BurgerMenu onClick={()=>{useNavigationStore.getState().setMobileMenuOpen(true)}}>
          <div /><div /><div />
        </BurgerMenu>
        :
        <MenuContainer>
          {["projekte", "profil", "kontakt", "impressum"].map((i, key)=><MenuItem key={key} bold={page === i}><Link to={"/" + i}>{i}</Link></MenuItem>)}
        </MenuContainer>
      }
    </Container>
  );
}