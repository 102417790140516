import styled from 'styled-components';
import { absoluteFill, colors } from '../../styles';
import { mediaMax, vp } from '../../common/breakpoints';

export const Container = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 120px;
  bottom: 100px;
`;

export const SliderContainer = styled.div`
  ${absoluteFill};
  .react-swipe-container{
    /* overflow: visible !important; */
    > div{
      /* overflow: visible !important; */
    }
  }
`;

export const SlideContainer = styled.div`
  position: relative;
  overflow: visible;
`;

export const SliderImg = styled.div`
  position: absolute;
  width: calc(100% - 50px);
  left: 25px;
  height: ${props => props.hasDescription ? 'calc(100% - 20px)' : '100%'};
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  > p {
    margin: 0;
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: -20px;
    background-color: white;
    /* transform: translateY(100%); */
  }
`;

export const Pagination = styled.div`
  position: absolute;
  left: 50%;
  bottom: -60px;
  transform: translateX(-50%);
  text-align: center;
`;

export const PaginationDot = styled.div`
  cursor: pointer;
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin: 1.5px 1.5px 0 1.5px;
  background-color: ${props => props.active ? colors.darkGray : colors.lightGray};
`;

const Button = styled.div`
  position: absolute;
  width: 30%;
  height: 100%;
  cursor: pointer;
    img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      opacity: 60%;
    }
  ${mediaMax('small',`
    display: none;
  `)}
`;

export const PreviousButton = styled(Button)`
  left: 40px;
  img {
    transform: translateY(-50%) scaleX(-1);
    left: 0;
  }
`;

export const NextButton = styled(Button)`
  right: 40px;
  img {
    right: 0;
  }
`;

export const ShortDescription = styled.div`
  ${mediaMax('small',`
    max-width: 150px;
  `)}
  ${vp.medium(`
  `)}
`;

export const LightLink = styled.span`
  font-weight: bold;
  text-decoration: underline;
  color: ${colors.midGray};
  cursor: pointer;
`;

export const ArrowContainer = styled.div`
`;

export const HUDcontainer = styled.div`
  position: absolute;
  display: inline-block;
  width: 100%;
  bottom: 0;
  left: 0;
  ${mediaMax('small',`
    background: white;
  `)}
`;
