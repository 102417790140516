import React from 'react';
import { Container, Richtext } from './styles';
import useDataStore from '../../services/DataService';
import { Link } from 'react-router-dom';

export default function BasicPage({content}) {

  const data = useDataStore(state => state[content]);

  // if (!data) return <div />;

  // console.log('data', data);

  if (!data) return null;

  return (
    <Container>
      {content === 'impressum' && <div style={{marginBottom: 20}}><Link to='/datenschutz'>Zur Datenschutzerklärung</Link></div>}
      <Richtext dangerouslySetInnerHTML={{__html: data.richtext}} />
    </Container>
  );
}