import create from '../utilities/zustand/create';
import axios from 'axios';

const REACT_APP_BASE_URL = 'https://backend.mapet-architekten.de'
const API_URL = REACT_APP_BASE_URL + '/api';
const xApiKey = 'yfjwkxH9ZgysGgyxfUHJD';

const useDataStore = create(module, (set, get) => ({
  loaded: false,
  projects: undefined,
  contact: undefined,
  impressum: undefined,
  datenschutz: undefined,
  test: () => {
    axios({
      headers: {
        'x-api-key': xApiKey,
      },
      method: 'get',
      url: API_URL + '/getAll',
      }).then(res => {
        // console.log('res', res);
        set({
          loaded: true,
          projects: [...res.data.projects],
          colors: {...res.data.colors},
          contact: res.data.contact,
          profile: res.data.profile,
          impressum: res.data.impressum,
          datenschutz: res.data.datenschutz,
        });
      });
  },
}));

export default useDataStore;
