import styled from 'styled-components';
import { colors } from '../../styles';
import { mediaMax } from '../../common/breakpoints';


export const Container = styled.div`
  width: 100%;
  color: ${colors.darkGray};
  height: 75px;
  margin-bottom: 20px;
  position: relative;
  border-bottom: 2px #ddd solid;
  ${mediaMax('small',`
    height: 50px;
    margin-bottom: 15px;
  `)}
  a {
    text-decoration: none;
    color: ${colors.darkGray};
  }
`;

export const MenuContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 25px;
  font-size: 16px;
  ${mediaMax('small',`
    bottom: 15px;
  `)}
`;

export const BurgerMenu = styled.div`
  position: absolute;
  right: 0;
  bottom: 15px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  /* background: blue; */
  > div {
    width: 100%;
    margin-bottom: 7px;
    height: 3px;
    background-color: ${colors.midGray};
  }
`;

export const Logo = styled.span`
  position: absolute;
  left: 0;
  bottom: 25px;
  font-size: 30px;
  a {
    font-weight: 400;
  }
  ${mediaMax('small',`
    font-size: 22px;
    bottom: 15px;
  `)}
  cursor: pointer;
`;

export const MenuItem = styled.span`
  margin-left: 15px;
  font-weight: ${props => props.bold ? "bold" : 'normal'};
  cursor: pointer;
`;