import create from '../utilities/zustand/create';

const useNavigationStore = create(module, set => ({
  showProject: undefined,
  mobileMenuOpen: false,
  setMobileMenuOpen: mobileMenuOpen => {
    set({mobileMenuOpen});
  },
  setShowProject: project => {
    set({showProject: project});
  },
}));

export default useNavigationStore;
