import styled, { createGlobalStyle, css } from 'styled-components';
import LatoLight from './assets/fonts/Lato-Light.woff2';
import LatoRegular from './assets/fonts/Lato-Regular.woff2';
import LatoBold from './assets/fonts/Lato-Bold.woff2';
import LatoBlack from './assets/fonts/Lato-Black.woff2';
import { mediaMax } from './common/breakpoints';

export const margins = {
  S: {
    top: 25,
    sides: 15,
    bottom: 15,
  },
  M: {
    top: 25,
    sides: 40,
    bottom: 40,
  },
  L: {
    top: 25,
    sides: 40,
    bottom: 40,
  },
};

export const colors = {
  // mapetGreen: '#9B9B5B',
  // mapetGreenLight: '#BABA78',
  lightGray: '#ddd',
  midGray: '#aaa',
  darkGray: '#555',
};

export const absoluteFill = css`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

export const centerVertically = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Lato';
    font-weight: 300;
    src: url('${LatoLight}') format('woff2');
  }
  @font-face {
    font-family: 'Lato';
    font-weight: 400;
    src: url('${LatoRegular}') format('woff2');
  }
  @font-face {
    font-family: 'Lato';
    font-weight: 500;
    src: url('${LatoBold}') format('woff2');
  }
  @font-face {
    font-family: 'Lato';
    font-weight: 600;
    src: url('${LatoBlack}') format('woff2');
  }
  body {
    margin: 0;
    font-size: 14px;
    overflow-x: hidden;
    font-family: 'Lato', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${colors.darkGray};
    ${mediaMax('small',`
      font-size: 12px;
    `)}
  }
  p{
    margin-top: 0;
  }
  h1 {
    margin: 0;
    font-size: 24px;
    font-weight: 400;
  }
  a {
    color: ${colors.darkGray};
  }
  strong {
    font-weight: 600;
  }
`;

export const AppContainer = styled.div`
  width: 100%;
  padding: ${margins.L.top}px ${margins.L.sides}px ${margins.L.bottom}px;
  max-width: 1580px;
  margin: 0 auto;
  box-sizing: border-box;
  ${mediaMax('medium',`
      padding: ${margins.M.top}px ${margins.M.sides}px ${margins.M.bottom}px;
  `)}
  ${mediaMax('small',`
      // padding: 15px 25px;
      padding: ${margins.S.top}px ${margins.S.sides}px ${margins.S.bottom}px;
  `)}
`;