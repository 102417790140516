import React from 'react';
import useNavigationStore from '../../services/NavigationService';
import useWindowStore from '../../services/WindowService';
import MobileMenu from '../MobileMenu';
import Projects from '../Projects';
import { Container } from './styles';
import { useParams } from 'react-router-dom';
import Contact from '../Contact';
import BasicPage from '../BasicPage';
import Profile from '../Profile';

export default function AppContent() {

  const { page } = useParams();
  const mobileMenuOpen = useNavigationStore(state => state.mobileMenuOpen);
  const isMobile = useWindowStore(state => state.isMobile);

  return (
    <Container>
      {page === 'projekte' && <Projects />}
      {page === 'profil' && <Profile />}
      {page === 'kontakt' && <Contact />}
      {page === 'impressum'&& <BasicPage content='impressum' />}
      {page === 'datenschutz'&& <BasicPage content='datenschutz' />}
      {isMobile && mobileMenuOpen && <MobileMenu />}
    </Container>
  );
}