import create from '../utilities/zustand/create';
import { breakPoints } from '../common/breakpoints';
import debounce from '../utilities/debounce';
import { prefixEventListener } from '../utilities/prefixed-events';

const useWindowStore = create(module, set => ({
  isMobile: false,
  width: 0,
  height: 0,

  init: () => {
    const onResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      set({ isMobile: width <= breakPoints.small.max, width, height });
    };

    prefixEventListener(window, 'resize', debounce(onResize, 250));
    onResize();
  },
}));

export default useWindowStore;
