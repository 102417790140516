import React from 'react';
import { Container, ProfileContainer, Image, Employee, Employees } from './styles';
import useDataStore from '../../services/DataService';

export default function Profile() {

  const data = useDataStore(state => state.profile);

  
  if (!data) return <div />;
  
  const {richtext, singleimage, employees} = data;

  return (
    <Container>
      <ProfileContainer dangerouslySetInnerHTML={{__html: richtext}} />
      <Image src={singleimage} alt='' />
      <Employees>
        {employees.map((e, i)=><Employee dangerouslySetInnerHTML={{__html: e}} key={i} />)}
      </Employees>
    </Container>
  );
}