import styled from 'styled-components';
import { absoluteFill, centerVertically } from '../../styles';
import { mediaMax, mediaMinMax } from '../../common/breakpoints';

const cellDistance = 14;

export const Container = styled.div`
  display: inline-block;
  width: 100%;
`;

export const CenterVertically = styled.div`
  ${centerVertically}
  width:100%;
  > * {
    margin: 0;
    line-height: 1em;
    text-align: center;
  }
  > h4 {
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 10px;
  }
  > p {
    font-size: 14px;
  }
`;

export const CellOverlay = styled.div`
  ${absoluteFill}
  /* background: rgba(155, 155, 91, 0.8); */
  background: rgb(${props => props.r + ',' + props.g + ',' + props.b}, 0.8);
  opacity: 0;
  transition: opacity 0.1s ease-out;
  &:hover{
    opacity: 1;
  }
`;

export const TableCell = styled.div`
  position: relative;
  float: left;
  width: 33.3333%;
  padding-bottom: 25%;
  ${mediaMinMax('medium', 'medium', `
    width: 50%;
    padding-bottom: 33%;
    &:nth-child(2n-1) {
      > div { margin: 0 ${cellDistance /2}px 0 0 !important; }
    }
    &:nth-child(2n) {
      > div { margin: 0 0 0 ${cellDistance /2}px !important; }
    }
  `)}
  ${mediaMax('small',`
    width: 100%;
    padding-bottom: 65%;
    > div {
      margin: 0 !important;
    }
  `)}
  margin-bottom: ${cellDistance}px;
  box-sizing: border-box;
  user-select: none;
  > div {
    position: relative;
    ${absoluteFill};
  }
  &:nth-child(3n-2) {
    > div { margin-right: ${cellDistance /2}px; }
  }
  &:nth-child(3n-1) {
    > div { margin: 0 ${cellDistance /2}px; }
  }
  &:nth-child(3n) {
    > div { margin-left: ${cellDistance /2}px; }
  }
`;

export const Cell = styled.div`
  ${absoluteFill};
  background-size: cover;
  color: white;
  text-align: center;
  font-size: 18px;
  cursor: pointer;
  background-image: ${props => props.bgImage ? "url('"+props.bgImage+"')" : 'none'};
  background-color: rgb(${props => props.r + ',' + props.g + ',' + props.b});
  transition: background-color 0.1s ease-out;
  &:hover {
    background-color: ${props => props.hoverColor};
  }
`;

export const CenteredText = styled.div`
  width: 100%;
  ${centerVertically}
`;
