import React from 'react';
import { Container, MenuContainer, MenuItem, CloseButton } from './styles';
import { Link, useParams } from 'react-router-dom';
import useNavigationStore from '../../services/NavigationService';

export default function MobileMenu() {

  const { page } = useParams();

  const CloseMenu = ()=>{useNavigationStore.getState().setMobileMenuOpen(false)};

  return (
    <Container>
      <MenuContainer>
        {["projekte", "profil", "kontakt", "impressum"].map((i, key)=>
          <MenuItem key={key} bold={page === i}>
            <Link to={"/" + i} onClick={CloseMenu}>
              {i}
            </Link>
          </MenuItem>
        )}
      </MenuContainer>
      <CloseButton onClick={CloseMenu}>
        <div className='one' />
        <div className='two' />
      </CloseButton>
    </Container>
  );
}
