import styled from 'styled-components';

export const Container = styled.div`
  h1{
    margin-bottom: 20px;
  }
`;

export const Richtext = styled.div`
`;
