import styled from 'styled-components';
import { mediaMax } from '../../common/breakpoints';

export const Container = styled.div`
  width: 100%;
  max-width: 880px;
  margin: 0 auto;
`;

export const Image = styled.img`
  margin-top: 18px;
  ${mediaMax('small',`
    margin-top: 10px;
  `)}
  width: 100%;
`;

export const ProfileContainer = styled.div`
  margin-top: 10px;
`;

export const Employees = styled.div`
  display: inline-block;
  margin: 25px 0;
  ${mediaMax('small',`
    margin: 15px 0;
  `)}
  >div:first-child{
    padding-left: 0;
    padding-right: 10px;
    ${mediaMax('small',`
      padding-right: 5px;
    `)}
  }
`;

export const Employee = styled.div`
  float: left;
  width: 50%;
  padding-left: 10px;
  ${mediaMax('small',`
      padding-left: 5px;
    `)}
  box-sizing: border-box;
`;
