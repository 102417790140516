import React, { useMemo } from 'react';
import { Container, Cell, CellOverlay, CenterVertically, CenteredText, TableCell } from './styles';
import useDataStore from '../../services/DataService';
import { useLocation, useNavigate } from 'react-router-dom';
import Project from '../Project';

export default function Projects() {

  const projects = useDataStore(state => state.projects);
  const colors = useDataStore(state => state.colors);

  const { hash } = useLocation();

  const showProject = useMemo(()=>{
    if (projects && hash !== '') {
      const pr = projects.find(p => p.identifier === hash.substring(1, hash.length));
      if (pr) return pr;
    }
    return undefined;
  }, [projects, hash])

  const navigate = useNavigate();

  const cells = useMemo(()=>{
    if(projects !== undefined) {
      const cells = projects.map(p=>{return {type: 'project',name: p.title, img: p.previewImage, subTitle: p.subTitle, identifier: p.identifier}});
      cells.splice(1, 0, {type: 'link', name: 'kontakt'});
      cells.splice(6, 0, {type: 'link', name: 'profil'});
      cells.splice(10, 0, {type: 'link_out', name: 'instagram', link: "https://www.instagram.com/mapetarchitekten/"});
      return cells;
    }
    return [];
  }, [projects]);

  if (showProject !== undefined) {
    return <Project project={showProject} />;
  }

  const onCellClick = c=>{
    if (c.type === 'project') {
      navigate(`/projekte#${c.identifier}`, {replace: true});
    } else if (c.type === 'link') {
      navigate(`/${c.name}`, {replace: true});
    } else if (c.type === 'link_out'){
      window.open(c.link);
    }
  };

  const renderCells = () => cells.map((c, k) => {
    if (c.type === 'project') return (
      <TableCell bgImg={c.img} key={k}>
          <div>
            <Cell bgImage={c.img} onClick={()=>{onCellClick(c)}}>
              <CellOverlay r={colors.main[0]} g={colors.main[1]} b={colors.main[2]}>
                <CenterVertically>
                  <h4>{c.name}</h4>
                  <p>{c.subTitle}</p>
                </CenterVertically>
              </CellOverlay>
            </Cell>
          </div>
      </TableCell>
      );
    return (
      <TableCell bgImg={c.img} key={k}>
        <div>
          <Cell onClick={()=>{onCellClick(c)}} r={colors.main[0]} g={colors.main[1]} b={colors.main[2]} hoverColor={colors.main_bright}>
            <CenteredText>{c.name}</CenteredText>
          </Cell>
        </div>
      </TableCell>)
      ;
  });

  return (
    <Container>
        {renderCells()}
    </Container>
  );
}